.LoadingSpinner-root {
  position: absolute;
  left: calc(50% - 60px);
  top: calc(50% - 60px);
  z-index: 1000;
  background-color: '#1D1D1D';
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
